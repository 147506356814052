<template>
  <PageTemplate>
		<Grid>
			<GridContainer size="12">
				<Heading size="3">Stack</Heading>
			</GridContainer>
			<GridContainer size="12 6@lg">
				<Card>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Heading size="5">Direction</Heading>
							<Paragraph>The direction defines the main-axis items are placed in the stack component.</Paragraph>
						</Stack>
					</CardSection>
					<CardSection>
						<Stack direction="column" space="3" align="left">
							<Stack direction="column row@sm" align="left" justify="left" space="1">
								<Stack space="1">
									<Button label="Row" :color="direction === 'row' ? 'primary' : 'light'" size="small" @click.native="setDirection('row')" />
									<Button label="Row Reverse" :color="direction === 'rowReverse' ? 'primary' : 'light'" size="small" @click.native="setDirection('rowReverse')" />
								</Stack>
								<Stack space="1">
									<Button label="Column" :color="direction === 'column' ? 'primary' : 'light'" size="small" @click.native="setDirection('column')" />
									<Button label="Column Reverse" :color="direction === 'columnReverse' ? 'primary' : 'light'" size="small" @click.native="setDirection('columnReverse')" />
								</Stack>
							</Stack>
							<Card>
								<CardSection>
									<Stack :direction="direction" space="2" wrap>
										<Button label="Button" color="secondary" size="small" />
										<Heading size="4">Heading</Heading>
										<Icon icon="favorite" size="medium" background="primary" color="light" />
										<Link text="Link" url="" display="inline" />
									</Stack>
								</CardSection>
							</Card>
						</Stack>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="12 6@lg">
				<Card>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Heading size="5">Justify</Heading>
							<Paragraph>Justify sets the alignment along the main-axis.</Paragraph>
						</Stack>
					</CardSection>
					<CardSection>
						<Stack direction="column" space="3" align="left">
							<Stack direction="column row@sm" align="left" justify="left" space="1">
								<Stack space="1">
									<Button label="Left" :color="justify === 'left' ? 'primary' : 'light'" size="small" @click.native="setJustify('left')" />
									<Button label="Right" :color="justify === 'right' ? 'primary' : 'light'" size="small" @click.native="setJustify('right')" />
									<Button label="Center" :color="justify === 'center' ? 'primary' : 'light'" size="small" @click.native="setJustify('center')" />
								</Stack>
								<Stack space="1">	
									<Button label="Space Around" :color="justify === 'spaceAround' ? 'primary' : 'light'" size="small" @click.native="setJustify('spaceAround')" />
									<Button label="Space Between" :color="justify === 'spaceBetween' ? 'primary' : 'light'" size="small" @click.native="setJustify('spaceBetween')" />
								</Stack>
								<Stack space="1">	
									<Button label="Space Evenly" :color="justify === 'spaceEvenly' ? 'primary' : 'light'" size="small" @click.native="setJustify('spaceEvenly')" />
								</Stack>
							</Stack>
							<Card>
								<CardSection>
									<Stack :justify="justify" space="2" wrap>
										<Button label="Button" color="secondary" size="small" />
										<Heading size="4">Heading</Heading>
										<Icon icon="favorite" size="medium" background="primary" color="light" />
										<Link text="Link" url="" display="inline" />
									</Stack>
								</CardSection>
							</Card>
						</Stack>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="12 6@lg">
				<Card>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Heading size="5">Align</Heading>
							<Paragraph>The align prop defines how the components are laid out along the cross axis.</Paragraph>
						</Stack>
					</CardSection>
					<CardSection>
						<Stack direction="column" space="3" align="left">
							<Stack direction="column row@sm" align="left" justify="left" space="1">
								<Stack space="1">
									<Button label="Top" :color="align === 'top' ? 'primary' : 'light'" size="small" @click.native="setAlign('top')" />
									<Button label="Center" :color="align === 'center' ? 'primary' : 'light'" size="small" @click.native="setAlign('center')" />
									<Button label="Bottom" :color="align === 'bottom' ? 'primary' : 'light'" size="small" @click.native="setAlign('bottom')" />
								</Stack>
								<Stack space="1">
									<Button label="Left" :color="align === 'left' ? 'primary' : 'light'" size="small" @click.native="setAlign('left')" />
									<Button label="Right" :color="align === 'right' ? 'primary' : 'light'" size="small" @click.native="setAlign('right')" />
								</Stack>
							</Stack>
							<Card>
								<CardSection>
									<Stack :align="align" :direction="align === 'left' || align === 'right' ? 'column' : 'row'" space="2" wrap>
										<Button label="Button" color="secondary" size="small" />
										<Heading size="4">Heading</Heading>
										<Icon icon="favorite" size="medium" background="primary" color="light" />
										<Link text="Link" url="" display="inline" />
									</Stack>
								</CardSection>
							</Card>
						</Stack>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="12 6@lg">
				<Card>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Heading size="5">Space</Heading>
							<Paragraph>The space prop sets the amount of space between each item inside the stack.</Paragraph>
						</Stack>
					</CardSection>
					<CardSection>
						<Stack direction="column" space="3" align="left">
							<Stack space="1">
								<Button label="0" :color="space === ' ' ? 'primary' : 'light'" size="small" @click.native="setSpace(' ')" />
								<Button label="1" :color="space === '1' ? 'primary' : 'light'" size="small" @click.native="setSpace('1')" />
								<Button label="2" :color="space === '2' ? 'primary' : 'light'" size="small" @click.native="setSpace('2')" />
								<Button label="3" :color="space === '3' ? 'primary' : 'light'" size="small" @click.native="setSpace('3')" />
							</Stack>
							<Card>
								<CardSection>
									<Stack :space="space" wrap>
										<Button label="Button" color="secondary" size="small" />
										<Heading size="4">Heading</Heading>
										<Icon icon="favorite" size="medium" background="primary" color="light" />
										<Link text="Link" url="" display="inline" />
									</Stack>
								</CardSection>
							</Card>
						</Stack>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="12 6@lg">
				<Card>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Heading size="5">Border</Heading>
							<Paragraph>To apply a border to a stack, use the border prop with the desired border location ex. border="tb" or border="lr".</Paragraph>
						</Stack>
					</CardSection>
					<CardSection>
						<Stack direction="column" space="3" align="left">
							<Stack direction="column row@sm" align="left" justify="left" space="1">
								<Stack space="1">
									<Button label="All" :color="border === 'a' ? 'primary' : 'light'" size="small" @click.native="setBorder('a')" />
								</Stack>
								<Stack space="1">
									<Button label="Left" :color="border === 'l' ? 'primary' : 'light'" size="small" @click.native="setBorder('l')" />
									<Button label="Right" :color="border === 'r' ? 'primary' : 'light'" size="small" @click.native="setBorder('r')" />
									<Button label="Left Right" :color="border === 'lr' ? 'primary' : 'light'" size="small" @click.native="setBorder('lr')" />
								</Stack>
								<Stack space="1">
									<Button label="Top" :color="border === 't' ? 'primary' : 'light'" size="small" @click.native="setBorder('t')" />
									<Button label="Bottom" :color="border === 'b' ? 'primary' : 'light'" size="small" @click.native="setBorder('b')" />
									<Button label="Top Bottom" :color="border === 'tb' ? 'primary' : 'light'" size="small" @click.native="setBorder('tb')" />
								</Stack>
							</Stack>
							<Card>
								<CardSection>
									<Stack justify="spaceEvenly" align="center" height="100px" width="100%" :border="border">
										<Button label="Button" color="secondary" size="small" />
										<Heading size="4">Heading</Heading>
										<Icon icon="favorite" size="medium" background="primary" color="light" />
										<Link text="Link" url="" display="inline" />
									</Stack>
								</CardSection>
							</Card>
						</Stack>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="12 6@lg">
				<Card>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Heading size="5">Size</Heading>
							<Paragraph>Add height=" " and width=" " props to set a custom height or width. This can be any web measurement ex. 100px, 50%, 10pc.</Paragraph>
						</Stack>
					</CardSection>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Stack height="100px" width="100%" border="a" justify="center">
								<Heading align="center" margin="none" size="5">100% x 100px</Heading>
							</Stack>
							<Stack height="50px" width="50%" border="a" justify="center">
								<Heading align="center" margin="none" size="5">50% x 50px</Heading>
							</Stack>
						</Stack>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="12">
				<Card>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Heading size="5">Stack Properties</Heading>
							<Paragraph></Paragraph>
						</Stack>
					</CardSection>
					<CardSection>
						<Table>
							<TableRow head>
								<TableCell>
									<Paragraph margin="none" bold>Property</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Type</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Options</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Explanation</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">direction</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">
										row<br/>
										rowReverse<br/>
										column<br/>
										columnReverse<br/>
									</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Defines the direction the items are placed in the stack.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">justify</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">
										left<br/>
										center<br/>
										right<br/>
										spaceBetween<br/>
										spaceAround<br/>
										spaceEvenly<br/>
									</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Defines how the items justify in the stacks.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">align</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">
										top<br/>
										bottom<br/>
										center<br/>
										left<br/>
										right<br/>
									</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">The vertical alignment for items in rows and the horizontal alignment for items in columns.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">space</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">1-3</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Sets the space between items in a stack.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">border</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">
										a<br/>
										l<br/>
										r<br/>
										lr<br/>
										t<br/>
										b<br/>
										tb<br/>
									</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Sets the border location on a stack.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">height</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">-</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Defines the height of the stack.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">width</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">-</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Defines the width of the stack.</Paragraph>
								</TableCell>
							</TableRow>
						</Table>
					</CardSection>
				</Card>
			</GridContainer>
		</Grid>
	</PageTemplate>
</template>

<script>
import PageTemplate from '@/views/designsystem/template/Page'; 
export default {
  components: {
		PageTemplate
  },
  data() {
    return {
      direction: 'row',
      justify: 'left',
      align: 'top',
      space: ' ',
      border: 'a',
		}
  },
  mounted() {
		setTimeout(() =>{ 
			this.stageTheme(this.theme);
		}, 30);
  },
	methods: {
		setDirection(e){
			this.direction = e
		},
		setJustify(e){
			this.justify = e
		},
		setAlign(e){
			this.align = e
		},
		setSpace(e){
			this.space = e
		},
		setBorder(e){
			this.border = e
		},
	}
}
</script>

<style lang="scss" scoped>
</style>
